@font-face {
    font-family: 'Isidora Sans Alt';
    src: url('../fonts/IsidoraSansAlt-Bold.eot');
    src: local('../fonts/Isidora Sans Alt Bold'), local('../fonts/IsidoraSansAlt-Bold'),
        url('../fonts/IsidoraSansAlt-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/IsidoraSansAlt-Bold.woff2') format('woff2'),
        url('../fonts/IsidoraSansAlt-Bold.woff') format('woff'),
        url('../fonts/IsidoraSansAlt-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Isidora Sans Alt';
    src: url('../fonts/IsidoraSansAlt-Regular.eot');
    src: local('../fonts/Isidora Sans Alt Regular'), local('fonts/IsidoraSansAlt-Regular'),
        url('../fonts/IsidoraSansAlt-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/IsidoraSansAlt-Regular.woff2') format('woff2'),
        url('../fonts/IsidoraSansAlt-Regular.woff') format('woff'),
        url('../fonts/IsidoraSansAlt-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}



@font-face {
    font-family: 'Isidora Sans Alt';
    src: url('../fonts/IsidoraSansAlt-Medium.eot');
    src: local('../fonts/Isidora Sans Alt Medium'), local('../fonts/IsidoraSansAlt-Medium'),
        url('../fonts/IsidoraSansAlt-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/IsidoraSansAlt-Medium.woff2') format('woff2'),
        url('../fonts/IsidoraSansAlt-Medium.woff') format('woff'),
        url('../fonts/IsidoraSansAlt-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}


@font-face {
    font-family: 'Isidora Sans Alt';
    src: url('../fonts/IsidoraSansAlt-SemiBold.eot');
    src: local('../fonts/Isidora Sans Alt SemiBold'), local('../fonts/IsidoraSansAlt-SemiBold'),
        url('../fonts/IsidoraSansAlt-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/IsidoraSansAlt-SemiBold.woff2') format('woff2'),
        url('../fonts/IsidoraSansAlt-SemiBold.woff') format('woff'),
        url('../fonts/IsidoraSansAlt-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Isidora Sans Alt';
    src: url('../fonts/IsidoraSansAlt-Bold.eot');
    src: local('../fonts/Isidora Sans Alt Bold'), local('../fonts/IsidoraSansAlt-Bold'),
        url('../fonts/IsidoraSansAlt-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/IsidoraSansAlt-Bold.woff2') format('woff2'),
        url('../fonts/IsidoraSansAlt-Bold.woff') format('woff'),
        url('../fonts/IsidoraSansAlt-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}


@font-face {
    font-family: 'gothambook';
    src: url('../fonts/gotham_book_font-webfont.woff2') format('woff2'),
        url('../fonts/gotham_book_font-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gothammedium';
    src: url('../fonts/gotham_medium-webfont.woff2') format('woff2'),
        url('../fonts/gotham_medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

:root {
    --primary-color: #000000;
    --secondary-color: #054965;
    --purple-color: #72045C;
    --blue-color: #0064A4;
    --sky-blue: #28AAE1;
    --orange-color: #E65900;
    --spacer-lg: 5.625rem;
    --spacer-md: 3.125rem;
    --hero-spacer: 130px;
    --font-primary: 'Lato', sans-serif;
    --font-secondary: 'Isidora Sans Alt';
    --font-weight-medium: 500;
    --font-weight-bold: bold;
    --font-weight-regular: normal;
    --font-weight-semibold: 600;
}

body{
    background: var(--primary-color);
    font-size: 1.2rem;
}

.primary-bg {
    background: var(--primary-color);
}

.secondary-bg {
    background: var(--secondary-color);
}

.purple-color {
    background: var(--purple-color);
}

.blue-color {
    background: var(--blue-color);
}

.sky-blue-color {
    background: var(--blue-color);
}

.orange-color {
    background: var(--orange-color);
}

.spacer-lg-vertically {
    padding: var(--spacer-lg) 0px;
}

.spacer-lg-top {
    padding-top: var(--spacer-lg);
}

.spacer-lg-bottom {
    padding-bottom: var(--spacer-lg);
}
.spacer-md-vertically {
      padding: var(--spacer-md) 0px;
}

.hero-spacer {
    padding-top: var(--hero-spacer);
}

.font-primary {
    font-family: var(--font-primary);
}

.font-secondary {
    font-family: var(--font-secondary);
}

.container-md {
    max-width: 1060px;
}

.container-sm {
    max-width: 930px;
}

:link {
    text-decoration: none;
}

img {
    max-width: 100%;
}

p a {
    text-decoration: underline !important;
    color: #fff;
}
p a:hover {
    text-decoration: none !important;
    color: #000;
    background-color: #fff;
}
/* TYPOGRAPHY */

h1,
.h1 {
    font-size: 4.5rem;
    line-height: 4rem;
}

.heading-h4 {
    font-size: 2.25rem;
}

.heading-h2 {
    font-size: 3.75rem;
}

.heading-typography {
    font-family: var(--font-secondary);

}

body {
    font-family: var(--font-primary);
}

.section__heading {
    margin-bottom: 20px;
}

.link {
    color: #fff !important;
    text-decoration: none;

    font-weight: 900;
    transition: all 300ms linear;
    position: relative;
    display: inline-block;
}

.link-font-lg {
    font-size: 1.5rem;
}

.link:hover {
    color: #FA8700 !important;
}

.link-circle,
.cards__block__inner__content .orange-color {
    width: 24px;
    display: flex;
    margin: 0 auto;
    height: 24px;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 35%;
    opacity: 0;
    visibility: hidden;
    transition: all 300ms linear;
}

.link-circle img,
.cards__block__inner__content .orange-color img {
    width: 13px;
    height: 13px;
    transform: rotate(266deg);
    transform-origin: 50% 50%;
}

.link:hover .link-circle,
.card__heading:hover .orange-color {
    right: -35px;
    opacity: 1;
    visibility: visible;
}

.paragraph-lg {
    font-size: 1.25rem;
}


/* SERVICES */

.services__blk {
    margin-bottom: 80px;
}


/* CASE STUDIES */

.owl-dots span {
    width: 10px;
    height: 10px;
    display: inline-block;
    background: #fff;
    border-radius: 50%;
    margin: 20px 10px 0px 0px;
}

.owl-dot.active span {
    background-color: var(--sky-blue);
}

/* team */


.team::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(0, 0, 0, 0.54);
}

.team {
    background: url(/public/assets/images/team.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.heading-lg {
    font-size: 46px;
}

/* footer */

.footer_seprator {
    position: absolute;
    left: 0;
    right: 0;
    top: 0px;
}

input {
    outline: none;
}

.form__fields input:not(input[type="checkbox"]) {
    height: 44px;
    border: none;
    width: 85%;
    background: #CCCCCC;
    border: 1px solid #707070;
    color: #36424A;
    padding-left: 22px;
    padding-right: 50px;
}

.form__fields input[type="text"]:focus {
    background-color: #fff;
}

.form__fields a.lets-icon {
    width: 24px;
    display: flex;
    margin: 0 auto;
    height: 24px;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    border: none;
    position: absolute;
	content: "";
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
}
button.lets-icon {
    height: 46px;
    margin-left: 10px;
    border-radius: 35px;
    border: none;
    width: 46px;
}
button.lets-icon img {
    transform: rotate(-90deg);
}

input[type="checkbox"] {
    margin-right: 10px;
}

.form__fields a.lets-icon img {
    width: 13px;
    height: 13px;
    transform: rotate(266deg);
    -webkit-transform: rotate(266deg);
    -moz-transform: rotate(266deg);
    transform-origin: 50% 50%;
}

.social-links a {
    width: 40px;
    height: 40px;
    background-color: #fff;
    color: #808285;
    border-radius: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    text-align: center;
    transition: all 300ms linear;
}

.social-links a:not(:last-child) {
    margin-right: 30px;
}

.social-links a i {
    font-size: 25px;
}

.facebook:hover {
    background-color: #3B5999;
    color: #fff;

}

.instagram:hover {
    background-color: #bc2a8d;

    color: #fff;
}

.linkdein:hover {
    background-color: #027BB6;

    color: #fff;
}

/* Media query css start */

@media screen and (max-width:768px){
	:root {
        --spacer-lg: 2.625rem;
		--spacer-md: 2.625rem;
        --hero-spacer: 60px;
    }
	h1, .h1 {
		font-size: 3.5rem;
		line-height: 3rem;
	}
}

@media screen and (max-width:576px) {
    :root {
        --spacer-lg: 2.625rem;
		--spacer-md: 2.625rem;
        --hero-spacer: 30px;
    }
    .hero-spacer {
        padding-top: 75px;
    }

    .heading-h4 {
        font-size: 1.5rem;
    }

    h1,
    .h1 {
        font-size: 2.5rem;
        line-height: 3rem;
    }

    .paragraph-lg {
        font-size: 1rem;
    }
}

.slick-slide img {
    display: inline;
    width: 180px;
}
.faBars{color: #fff;}